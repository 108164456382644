.register-btn {
    background-color: rgb(52, 152, 219);
    color: white;
    padding: 8px;
   
    
    
    border-radius: 4px;
  
    box-shadow: 2px 2px 4px 0px grey;
    /* border: 2px solid pink; */
    border-style: none;
    position: relative;
    z-index: 0;
  }
  
  .register-btn::before {
    content: "";
    background-color:rgb(40, 116, 166 );
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 0.8s;
    z-index: -1;
  
  }
  
  .register-btn:hover::before {
    transform: scaleX(1);
  }