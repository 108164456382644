/* AuthProvider.css */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(1px); /* Apply blur effect to background */
    z-index: 9999; /* Ensure loader is on top of everything */
  }
  