/* src/FoodAnalysis.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('https://www.example.com/food-background.jpg') no-repeat center center fixed;
    background-size: cover;
    padding: 20px;
  }
  
  .intro {
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .intro-title {
    font-size: 2rem;
    margin: 0;
  }
  
  .intro-text {
    font-size: 1rem;
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .upload-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .upload-button[type="file"] {
    background: #28a745;
  }
  
  .result-container {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    overflow: auto;
  }
  
  .heading {
    color: #333;
    margin: 0;
  }
  
  .result-text {
    color: #555;
    white-space: pre-wrap; /* Ensures JSON formatting is preserved */
  }
  
  @media (max-width: 768px) {
    .intro-title {
      font-size: 1.5rem;
    }
  
    .intro-text {
      font-size: 0.875rem;
    }
  
    .upload-button {
      font-size: 14px;
    }
  }
  