
.firstContainer {
  margin-top: 8rem;
}

.SchoolName-text {
    text-align: center;
    background-color: beige;
}

.schoolName {
    font-weight: 500;
    color:#58A7FA ;
}

.school-desc {
    
    box-shadow: 1px  3px 6px #c5c7ca;
    margin-top: 3rem;
    color: #DBDCDD;
    background-color: #01162C ;
    border-radius: 8px;
    padding: 1rem;
    
    
}

.bodyText {
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
    

}